import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faTwitter, faInstagram  } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faMapMarker } from '@fortawesome/free-solid-svg-icons';


export default function Footer() {
  return (
    <>
      <footer className="footer text-white p-4  bottom-0 mt-10 w-full flex flex-wrap justify-between">
          <div className="footer-column">
            <div className="logo-container">
              <img src="/kc_footer.png" alt="Logo" className="footer-logo" />
            </div>
            <div className="social-icons">
              <FontAwesomeIcon icon={faTwitter} className="social-icon" />
              <FontAwesomeIcon icon={faFacebook} className="social-icon" />
              <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
              <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            </div>
            <p className="copyright-message">© 2025 Konzortia Capital Inc.<br/> All Rights Reserved.</p>
          </div>
          <div className="footer-column">
            <div className="footer-info">
              <div className="footer-info-item">
                <FontAwesomeIcon icon={faMapMarker} className="info-icon" />
                <span>99 Wall Street Suite 5895 <br/> New York, NY 10006</span>
              </div>
              <div className="footer-info-item">
                <FontAwesomeIcon icon={faPhone} className="info-icon" />
                <span>+1 (833) 930-2161</span>
              </div>
              <div className="footer-info-item">
                <FontAwesomeIcon icon={faEnvelope} className="info-icon" />
                <span>capital@konzortia.capital</span>
              </div>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-info-item"><p>Konzortia Capital Inc. Holding registration no. 3897083<br/>Special Purpose Vehicle Registered in Delaware, USA<br/> Konzortia Capital SVP 001 LLC, Incorporation no. 3884967</p></div>
          </div>
          <div className="footer-note">
            <p>This website is used for marketing purpose. We are legally required to have a <a href="">privacy policy</a> for capture data from people who visit our site.</p>
          </div>
          <img src="/footer_bottom.png" alt="Decorative Image" className="bottom-right-image" />
      </footer>
    </>
  )
}
